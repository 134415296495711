<template>
  <div :class="$style.block">
    <slot />
  </div>
</template>

<style lang="scss" module>
.block {
  border-radius: var(--radius-8xl);
  background: var(--general-underlay-1-lvl);
  padding: var(--spacing-6xl);

  @media screen and (max-width: $md) {
    border-radius: var(--radius-7xl);
    padding: var(--spacing-5xl, 32px) var(--spacing-4xl, 24px);
  }

  @media screen and (max-width: $sm) {
    border-radius: var(--radius-6xl);
    padding: var(--spacing-3xl, 22px) var(--spacing-2lg, 16px)
      var(--spacing-2lg, 16px) var(--spacing-2lg, 16px);
  }
}
</style>
